export default [
  {
    name: 'Не обязятельное',
    id: 0,
  },
  {
    name: 'Обязятельное',
    id: 1,
  },
]
