<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >{{text}}</a>
    <span slot="name" slot-scope="record"> {{ record.ru ? record.ru.name : '' }}</span>
    <span slot="type" slot-scope="record"> {{ record.type }}</span>
    <span slot="alias" slot-scope="record">{{ record.alias }}</span>
    <span slot="position" slot-scope="record">{{ record.position }}</span>
    <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
    <span slot="action" slot-scope="record">
            <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$emit('edit', record.id)">
              <i class="fe fe-edit mr-2" />
              Изменить
            </a>
              <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$emit('overview', record.id)">
                <small>
                  <i class="fe fe-more-horizontal mr-2" />
                </small>
                Просмотр
              </a>
          </span>
  </a-table>
</template>

<script>

export default {
  name: 'ContactsTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Тип',
          dataIndex: 'type',
        },
        {
          title: 'Алиас',
          scopedSlots: { customRender: 'alias' },
        },
        {
          title: 'Позиция',
          scopedSlots: { customRender: 'position' },
        },
        {
          title: 'Дата создания',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
</style>
